import React, { FC, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { LANGUAGE, THEME } from 'config/env-vars';

import { loadConfig } from 'utils/load-config';

import { getWonBonus } from 'redux/reducers/won-bonus';

const WonContent: FC = () => {
  const [bonusTextData, setBonusTextData] = useState<IText>();
  const [regUrl, setRegUrl] = useState<string>('');

  const wonBonus = useSelector(getWonBonus);

  const handleOPenRegistration = () => {
    window.open(regUrl);
  };

  useEffect(() => {
    loadConfig('bonus-config.json').then(response => {
      setBonusTextData(response.texts[LANGUAGE as TLanguages][THEME as TThemes]);
      setRegUrl(response.registrationUrl[LANGUAGE as TLanguages]);
    });
  }, []);

  return (
    <div className="won-content">
      <div className="content-wrap">
        <h1>{bonusTextData?.modalTitle}</h1>
        <p>{bonusTextData?.modalDescription}</p>

        <div className="amount-wrap">
          <h3>{bonusTextData?.win}</h3>

          {wonBonus && (
            <p>
              <span>{wonBonus.bonusAmount}</span>
              {bonusTextData && bonusTextData[wonBonus.bonusType]}
            </p>
          )}
        </div>
      </div>

      {/* <button onClick={() => dispatch(setModalTypeAction('reg'))}>{takeBonus}</button> */}
      <button onClick={handleOPenRegistration}>{bonusTextData?.takeBonus}</button>
    </div>
  );
};

export default WonContent;
