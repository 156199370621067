import { AnyAction, combineReducers, Reducer } from 'redux';

import modalTypeStore from 'redux/reducers/modal-type';
import wonBonusStore from 'redux/reducers/won-bonus';

export const appReducer = combineReducers({
  wonBonusStore,
  modalTypeStore,
});

export type RootState = ReturnType<typeof appReducer>;

export const rootReducer: Reducer = (state: RootState, action: AnyAction) => appReducer(state, action);
