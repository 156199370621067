import React from 'react';

import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { LANGUAGE, THEME } from 'config/env-vars';

import { store } from 'redux/store';

import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import 'assets/styles/bonus.scss';

document.documentElement.setAttribute('theme', THEME || 'green');
document.documentElement.setAttribute('lang', LANGUAGE || 'en');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);

serviceWorkerRegistration.unregister();
reportWebVitals();
