import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';

import { LANGUAGE, THEME, WON_BONUSES } from 'config/env-vars';

import { loadConfig } from 'utils/load-config';

import { setModalTypeAction } from 'redux/reducers/modal-type';
import { setWonBonusAction } from 'redux/reducers/won-bonus';

import chip from 'assets/images/purple/chip.svg';
import crown from 'assets/images/purple/crown.svg';

const wonBonuses: number[] = JSON.parse(WON_BONUSES || '[]');

interface IWheel {
  disableSpinButton?: boolean;
  wonId?: number;
}

const Wheel = (props: IWheel) => {
  const { disableSpinButton, wonId = false } = props;

  const dispatch = useDispatch();

  const [bonusTextData, setBonusTextData] = useState<IText>();
  const [spinPosition, setSpinPosition] = useState<string>('');
  const [bonuses, setBonuses] = useState<IBonuses[]>([]);
  const [wonBonusDeg, setWonBonusDeg] = useState<number>(0);
  const [wonNumber, setWonNumber] = useState<number>(0);
  const [disableSpin, setDisableSpin] = useState<boolean>(false);

  const makeRandom = useCallback((prevNumber: number): number => {
    const randomIndex = Math.floor(Math.random() * wonBonuses.length);
    const _prevNumber = wonBonuses.indexOf(prevNumber);

    if (randomIndex !== _prevNumber) {
      return randomIndex;
    } else {
      return makeRandom(prevNumber);
    }
  }, []);

  const handleSpin = useCallback(() => {
    setDisableSpin(true);
    const spinCount = 2 * 360;
    const randomIndex = makeRandom(wonNumber);
    const _wonNumber = wonBonuses[randomIndex] || 4;
    const wonDeg = 360 - (_wonNumber - 1) * 36;
    const resetDeg = wonBonusDeg === 0 ? 0 : (wonNumber - 1) * 36;

    setWonBonusDeg(wonBonusDeg + spinCount + resetDeg + wonDeg);

    setTimeout(() => {
      const wonBonus = bonuses.find(bonus => bonus.id === _wonNumber);

      setWonNumber(_wonNumber);
      dispatch(setWonBonusAction(wonBonus));
      dispatch(setModalTypeAction('win'));
      setDisableSpin(false);
    }, 4500);
  }, [bonuses, dispatch, makeRandom, wonBonusDeg, wonNumber]);

  const renderSpinButton = useMemo(
    () => (
      <button className="wheel__spin" onClick={handleSpin} disabled={disableSpinButton || disableSpin}>
        <span>{bonusTextData?.spin}</span>
      </button>
    ),
    [bonusTextData?.spin, disableSpin, disableSpinButton, handleSpin],
  );

  useEffect(() => {
    if (wonId) {
      setWonBonusDeg(360 - (wonId - 1) * 36);
    }
  }, [wonId]);

  useEffect(() => {
    loadConfig('bonus-config.json').then(response => {
      setBonuses(response.bonuses);
      setSpinPosition(response.buttonPosition[THEME as TThemes]);
      setBonusTextData(response.texts[LANGUAGE as TLanguages][THEME as TThemes]);
    });
  }, []);

  return (
    <div className="wheel-wrap">
      {bonusTextData?.leftText && !wonId && (
        <div className="left-text">
          <p> {bonusTextData.leftText}</p>

          {spinPosition === 'left' && renderSpinButton}
        </div>
      )}

      <div className="wheel">
        <div className="wheel__arrow" />

        <div className={`wheel__dot ${wonId ? '' : 'animate'}`} style={{ rotate: `${wonBonusDeg}deg` }} />

        {THEME === 'purple' && (
          <>
            <img src={chip} className="wheel__chip" alt="" />
            <img src={crown} className="wheel__crown" alt="" />
          </>
        )}

        <div className={`wheel__body ${wonId ? '' : 'animate'}`} style={{ rotate: `${wonBonusDeg}deg` }}>
          {bonuses.map(bonusItem => {
            const { bonusAmount, bonusType, id } = bonusItem;

            return (
              <div className="wheel__body__bonus-item" key={id}>
                <p className="wheel__body__bonus-type">{bonusType}</p>
                <p className="wheel__body__bonus-amount">{bonusAmount}</p>
              </div>
            );
          })}
        </div>

        {spinPosition === 'center' && renderSpinButton}
      </div>

      {bonusTextData?.rightText && !wonId && (
        <div className="right-text">
          <p>{bonusTextData.rightText}</p>

          {spinPosition === 'right' && renderSpinButton}
        </div>
      )}
    </div>
  );
};

export default Wheel;
