import { createSlice } from '@reduxjs/toolkit';

const initialState: Nullable<TModalContentType> = null;

export const modalTypeSlice = createSlice({
  name: 'modalTypeStore',
  initialState,
  reducers: {
    setModalTypeAction: (state, action) => {
      return action.payload;
    },
  },
});

export const { setModalTypeAction } = modalTypeSlice.actions;

export const getModalType = (state: { modalTypeStore: Nullable<TModalContentType> }) => state.modalTypeStore;

export default modalTypeSlice.reducer;
