import { createSlice } from '@reduxjs/toolkit';

const initialState: Nullable<IBonuses> = null;

export const wonBonusSlice = createSlice({
  name: 'wonBonusStore',
  initialState,
  reducers: {
    setWonBonusAction: (state, action) => {
      return action.payload;
    },
  },
});

export const { setWonBonusAction } = wonBonusSlice.actions;

export const getWonBonus = (state: { wonBonusStore: Nullable<IBonuses> }) => state.wonBonusStore;

export default wonBonusSlice.reducer;
