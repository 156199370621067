import React, { useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getModalType, setModalTypeAction } from 'redux/reducers/modal-type';

import closeImage from 'assets/images/close.svg';

interface IModal {
  children: React.ReactNode;
  modalOpen: boolean;
}

export const Modal = (props: IModal) => {
  const { children, modalOpen } = props;

  const dispatch = useDispatch();
  const modalType = useSelector(getModalType);

  const dialogRef = useRef<HTMLDialogElement | null>(null);

  const handleClose = () => {
    if (dialogRef.current) {
      dialogRef.current.close();
      dispatch(setModalTypeAction(null));
    }
  };

  useEffect(() => {
    if (dialogRef.current && modalOpen) {
      dialogRef.current.showModal();
    }
  }, [modalOpen]);

  return (
    // eslint-disable-next-line no-alert
    <dialog
      className={`modal ${modalType}`}
      ref={dialogRef}
      onKeyDown={event => event.key === 'Escape' && handleClose()}
    >
      <button className="close-icon" type="button" onClick={handleClose}>
        <img src={closeImage} alt="close icon" />
      </button>

      {children}
    </dialog>
  );
};

export default Modal;
