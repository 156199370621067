import React from 'react';

import { useSelector } from 'react-redux';

import { THEME } from 'config/env-vars';

import { getModalType } from 'redux/reducers/modal-type';

import Modal from 'core/modal/modal';

import RegistrationContent from 'components/modal-contents/registration-content';
import WonContent from 'components/modal-contents/won-content';
import Wheel from 'components/wheel/wheel';

function App() {
  const modalType = useSelector(getModalType);

  return (
    <section className={`main ${THEME}`}>
      <div className="background-wrap" />

      <Wheel />

      <Modal modalOpen={modalType === 'reg' || modalType === 'win'}>
        {modalType === 'win' && <WonContent />}
        {modalType === 'reg' && <RegistrationContent />}
      </Modal>
    </section>
  );
}

export default App;
