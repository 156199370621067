import React, { FC, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { LANGUAGE, THEME } from 'config/env-vars';

import { loadConfig } from 'utils/load-config';

import { getWonBonus } from 'redux/reducers/won-bonus';

import Wheel from 'components/wheel/wheel';

const RegistrationContent: FC = () => {
  const [bonusTextData, setBonusTextData] = useState<IText>();

  const wonBonus = useSelector(getWonBonus);

  useEffect(() => {
    loadConfig('bonus-config.json').then(response => {
      setBonusTextData(response.texts[LANGUAGE as TLanguages][THEME as TThemes]);
    });
  }, []);

  return (
    <div className="reg-content">
      <div className="reg-content__background" />

      <div className="win-side">
        <div className="amount-wrap">
          <h3>{bonusTextData?.win}</h3>

          {wonBonus && (
            <p>
              <span>{wonBonus.bonusAmount}</span> {bonusTextData && bonusTextData[wonBonus.bonusType]}
            </p>
          )}
        </div>

        <Wheel disableSpinButton={true} wonId={wonBonus?.id} />
      </div>

      <div className="form-side"></div>
    </div>
  );
};

export default RegistrationContent;
